@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-Black.woff2") format("woff2"),
    url("./assets/fonts/Averta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Averta-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-Bold.woff2") format("woff2"),
    url("./assets/fonts/Averta-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-ExtraBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta-ExtrathinItalic";
  src: url("./assets/fonts/Averta-ExtrathinItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-ExtrathinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta-Extrathin";
  src: url("./assets/fonts/Averta-Extrathin.woff2") format("woff2"),
    url("./assets/fonts/Averta-Extrathin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-SemiboldItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-Light.woff2") format("woff2"),
    url("./assets/fonts/Averta-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta-RegularItalic";
  src: url("./assets/fonts/Averta-RegularItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-Semibold.woff2") format("woff2"),
    url("./assets/fonts/Averta-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/Averta-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-Thin.woff2") format("woff2"),
    url("./assets/fonts/Averta-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./assets/fonts/Averta-Regular.woff2") format("woff2"),
    url("./assets/fonts/Averta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/roboto-v30-latin-regular.eot");
  src: local(""),
    url("./assets/fonts/roboto-v30-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    url("./assets/fonts/roboto-v30-latin-regular.woff") format("woff"),
    url("./assets/fonts/roboto-v30-latin-regular.ttf") format("truetype"),
    url("./assets/fonts/roboto-v30-latin-regular.svg#Roboto") format("svg");
}

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/open-sans-v34-latin-regular.eot");
  src: local(""),
    url("./assets/fonts/open-sans-v34-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans-v34-latin-regular.woff2") format("woff2"),
    url("./assets/fonts/open-sans-v34-latin-regular.woff") format("woff"),
    url("./assets/fonts/open-sans-v34-latin-regular.ttf") format("truetype"),
    url("./assets/fonts/open-sans-v34-latin-regular.svg#OpenSans") format("svg");
}

body {
  position: relative;
  font-family: "Open Sans" !important;
}

* {
  font-family: "Open Sans" !important;
}

button {
  font-family: "Roboto" !important;
}
